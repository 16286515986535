/* eslint-disable quote-props */
const TEAM_ORDER = {
  'Group Leaders': 'Group Leader',
  'Lab Managers': 'Lab Manager',
  Staff: 'Staff',
  'Post Docs': 'Post Doc',
  'PhD Students': 'PhD Student',
  'Pre-doctoral Students': 'Pre-doctoral Student',
};

export default TEAM_ORDER;
