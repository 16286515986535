import React from 'react';

import PersonCard from './Card';

// eslint-disable-next-line arrow-body-style
const TeamList = ({ persons }) => {
  return !!persons && (
    <div className="team__wrapper">
      { persons.map(({ position, team }) => (
        <div className="team__list__wrapper" key={ position } id={ position }>
          <h3 className="page__subtitle">{ position }</h3>
          <div className="team__list">
            { team.map(({ node }) => (
              <PersonCard
                key={ node.id }
                person={ node }
              />
            )) }
          </div>
        </div>
      )) }
    </div>
  );
};

export default TeamList;
