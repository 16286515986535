import React, { useMemo, useRef } from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';

import SEO from 'Components/SEO';
import ScrollUp from 'Components/ScrollUp';

import TeamList from 'Components/team/List';
import TeamPositions from 'Components/team/Positions';
import TEAM_ORDER from 'Components/team/order';

export default ({ data }) => {
  const { edges: team } = data.allMarkdownRemark;
  const filtersRef = useRef(null);

  const byPosition = _.groupBy(team, ({ node }) => node.frontmatter.position);

  const positions = useMemo(
    () => [ ...new Set(
      Object.keys(TEAM_ORDER).concat(Object.keys(byPosition)),
    ) ].filter(position => !!byPosition[position] && !!byPosition[position] > 0),
    [ byPosition ],
  );

  // `byPosition` ordered by `positions`
  const persons = useMemo(
    () => positions.map(position => ({
      position,
      team: byPosition[position],
    })),
    [ byPosition, positions ],
  );

  return (
    <PageWrapper>
      <SEO title="Team" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">Team</h1>
        <ScrollUp scrollRef={ filtersRef } />
        <TeamPositions positions={ positions } scrollRef={ filtersRef } />
        <TeamList persons={ persons } />
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: {
      frontmatter: {
        templateKey: {eq: "team"},
        current: {eq: true}
      },
    }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            position
            telephone
            email
            current
            image {
              childImageSharp {
                fixed(width: 140) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            showReadMore
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
